export type LocaleLabel = string;

export const isValidLocale = (locale: LocaleLabel) => {
    try {
        // @ts-ignore
        new Intl.Locale(locale);
        return true;
    } catch (e: unknown) {
        return false;
    }
};
