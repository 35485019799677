import React, {useState} from 'react';
import styled from 'styled-components';
import {TextField} from '@material-ui/core';

import {isValidLocale, LocaleLabel} from './std/locale';

import {ControlLabel, PanelItemWrapper} from './PanelItemWrapper';

const TextFieldWrapper = styled(PanelItemWrapper)`
    min-width: 12rem;
`;

type LocaleInputProps = {
    value: LocaleLabel;
    onAssignment(locale: LocaleLabel): void;
}

export const LocaleInput = ({value, onAssignment}: LocaleInputProps) => {
    const [provisionalLocale, setProvisionalLocale] = useState<LocaleLabel>(value);

    const handleLocaleModification = (event: React.ChangeEvent<{ value: unknown }>) => {
        setProvisionalLocale(event.target.value as LocaleLabel);
    }

    const handleSubmit = (_event: any) => {
        if (isValidLocale(provisionalLocale)) {
            onAssignment(provisionalLocale);
        }
    }

    /* why is labelId = 'locale-apply' instead of just 'locale'?? */
    return (
        <TextFieldWrapper>
            <ControlLabel caption={'Locale'} labelId={'locale-apply'}/>
            <TextField
                id={'locale'}
                value={provisionalLocale}
                fullWidth={true}
                helperText={"Examples: en, en-GB, es, pt-PT, fr"}
                error={!isValidLocale(provisionalLocale)}
                onChange={handleLocaleModification}
                onBlur={handleSubmit}
                onSubmit={handleSubmit}
            />
        </TextFieldWrapper>
    );
};
