import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import {range} from '../std/Integer';

import {MonthNumber} from './MonthModel';

dayjs.extend(weekday);

const nAsMonth = (n: number) => {
    return ((n >= 1 && n <= 12) ? n : 1) as MonthNumber;
};

export const listMonthNumbers: MonthNumber[] = range(12, 1).map(nAsMonth);
