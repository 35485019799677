import React from 'react';
import styled from 'styled-components';
import {MenuItem, Select} from '@material-ui/core';

import {LocaleLabel} from './std/locale';

import {FirstDayOfWeek} from './model/WeekModel';
import {localisedSunday, localisedMonday} from './model/WeekFunctions';

import {ControlLabel, PanelItemWrapper} from './PanelItemWrapper';

const SelectWrapper = styled(PanelItemWrapper)`
    min-width: 16rem;
`;

type WeekStartChooserProps = {
    value: FirstDayOfWeek;
    onChange(newValue: FirstDayOfWeek): void;
    locale: LocaleLabel;
}

export const WeekStartChooser = ({value, onChange, locale}: WeekStartChooserProps) => {
    const handleWeekChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as FirstDayOfWeek);
    };

    return (
        <SelectWrapper>
            <ControlLabel caption={'Week starts on'} labelId={'week-starts-with-labelVh'}/>
            <Select
                labelId={'week-starts-with-labelVh'}
                value={value}
                fullWidth={true}
                autoWidth={false}
                onChange={handleWeekChange}
            >
                <MenuItem value={"sunday"}>{localisedSunday(locale)}</MenuItem>
                <MenuItem value={"monday"}>{localisedMonday(locale)}</MenuItem>
            </Select>
        </SelectWrapper>
    );
};
