import * as React from 'react';
import styled from 'styled-components';
import {FontSizeProps} from './FontSizeProps';

type DayCellWrapperProps = {
    isPrimary: boolean;
    fontSizePrint: number;
    fontSizeScreen: number;
}

const DayCellWrapper = styled.div((
    {isPrimary, fontSizePrint, fontSizeScreen}: DayCellWrapperProps
) => {
    const styleParams = isPrimary ? {
        print: {
            border: "#888",
            text: "#000"
        },
        screen: {
            center: "#fff",
            edge: "#fafafa",
            text: "#000"
        }
    } : {
        print: {
            border: "#eee",
            text: "#eee"
        },
        screen: {
            center: "#4442",
            edge: "#7772",
            text: "#bbb"
        }
    }

    return `
padding-left: 12%;
padding-top: 8%;

@media print {
    border-bottom: 0.2mm solid ${styleParams.print.border};
    border-right: 0.2mm solid ${styleParams.print.border};
    border-radius: 12%;
    
    color: ${styleParams.print.text};
    
    font-size: ${fontSizePrint}vh;
}

@media screen {
    border-radius: 0.2rem;

    background: radial-gradient(
        circle 2rem at center,
        ${styleParams.screen.center},
        ${styleParams.screen.edge}
    );

    color: ${styleParams.screen.text};

    font-size: ${fontSizeScreen}vh;
}
`;
});

type DayCellProps = FontSizeProps & {
    dayOfMonth: number;
    inCurrentMonth: boolean;
}

export const DayCell = (
    {dayOfMonth, inCurrentMonth, fontSizePrint, fontSizeScreen}: DayCellProps
) => (
    <DayCellWrapper
        isPrimary={inCurrentMonth}
        fontSizePrint={fontSizePrint}
        fontSizeScreen={fontSizeScreen}
    >
        {dayOfMonth}
    </DayCellWrapper>
);
