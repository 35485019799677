import React, {useState} from 'react';
import styled from 'styled-components';
import {
    Drawer,
    IconButton,
    Paper, PaperProps
} from '@material-ui/core';
import {SettingsTwoTone as MenuIcon, Done as CloseIcon} from '@material-ui/icons';

import {LocaleLabel} from './std/locale';

import {Year} from './model/YearModel';
import {FirstDayOfWeek} from './model/WeekModel';
import {CURRENT_YEAR} from './model/YearFunctions';
import {CalendarMode} from './model/CalendarMode';

import {WeekStartChooser} from './WeekStartChooser';
import {CalendarModeChooser} from './CalendarModeChooser';
import {Calendar} from './Calendar';
import {LocaleInput} from './LocaleInput';
import {YearChooser} from './YearChooser';

const DrawerHandleWrapper = styled.div`
    @media print {
        display: none;
    }
    
    @media screen {
        position: fixed;
        z-index: 100000;
        top: 0;
        right: 0;
        border-bottom-left-radius: 8px;
        border: 0.5px solid #ddd;
    
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        background: #fff;
    }
`;

/**
 * The Drawer needs to be wrapped in order to display any part of it on print media.
 * Otherwise, a fine top border line is printed.
 */
const DrawerWrapper = styled.div`
    @media print {
        display: none;
    }
`;

const ConfigPaper = (props: PaperProps) => (
    <Paper
        className={props.className}
        style={{backgroundColor: '#fefefa'}}
        square={true}
    >
        {props.children}
    </Paper>
);

const ConfigPanel = styled(ConfigPaper)`
    @media print {
        display: none;
    }

    @media screen {
        display: flex;
        max-width: calc(100vw - 1rem);

        flex-direction: row;
        max-height: 80vh;
        flex-wrap: wrap;
        justify-content: start;
        justify-items: stretch;
    }
`;

function App() {
    const [weekStartsOn, setWeekStartsOn] = useState<FirstDayOfWeek>("monday");
    const [calendarMode, setCalendarMode] = useState<CalendarMode>("full-page-portrait-with-image");
    const [year, setYear] = useState<Year>(CURRENT_YEAR + 1);
    const [isConfiguring, setConfiguring] = useState<boolean>(true);
    const [locale, setLocale] = useState<LocaleLabel>('default');

    const onDrawerToggle = (_event: unknown) => {
        setConfiguring(!isConfiguring);
    };

    return (
        <>
            <DrawerHandleWrapper>
                <IconButton onClick={onDrawerToggle}>
                    {isConfiguring ? (
                        <CloseIcon color={'primary'}/>
                    ) : (
                        <MenuIcon color={'primary'}/>
                    )}
                </IconButton>
            </DrawerHandleWrapper>
            <DrawerWrapper>
                <Drawer
                    transitionDuration={200}
                    anchor={'top'}
                    variant={'persistent'}
                    open={isConfiguring}
                >
                    <ConfigPanel>
                        <LocaleInput
                            value={locale}
                            onAssignment={setLocale}
                        />
                        <WeekStartChooser
                            value={weekStartsOn}
                            onChange={setWeekStartsOn}
                            locale={locale}
                        />
                        <CalendarModeChooser
                            value={calendarMode}
                            onChange={setCalendarMode}
                        />
                        <YearChooser
                            value={year}
                            onChange={setYear}
                        />
                    </ConfigPanel>
                </Drawer>
            </DrawerWrapper>
            <Calendar
                year={year}
                weekStartsOn={weekStartsOn}
                mode={calendarMode}
                locale={locale}
            />
        </>
    );
}

export default App;
