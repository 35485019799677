import React from 'react';
import styled from 'styled-components';

import {Slider} from '@material-ui/core';

import {range} from './std/Integer';

import {Year} from './model/YearModel';
import {CURRENT_YEAR} from './model/YearFunctions';

import {ControlLabel, PanelItemWrapper} from './PanelItemWrapper';

const YEAR_RANGE: Year[] = range(1, 0).map(n => n + CURRENT_YEAR);

const SliderWrapper = styled(PanelItemWrapper)`
`;

type YearChooserProps = {
    value: Year;
    onChange(year: Year): void;
}

export const YearChooser = ({value, onChange}: YearChooserProps) => {
    const handleYearChange = (event: any, newValue: number | number[]) => {
        onChange(newValue as Year);
    };

    return (
        <SliderWrapper>
            <ControlLabel caption={'Year'} labelId={'year-slider'}/>
            <Slider
                value={value}
                about={'Calendar Year'}
                aria-labelledby={'year-slider'}
                onChange={handleYearChange}
                min={YEAR_RANGE[0]}
                max={YEAR_RANGE[YEAR_RANGE.length - 1]}
                step={1}
                orientation={'horizontal'}
                inputMode={'numeric'}
                marks={true}
                valueLabelDisplay={'auto'}
            />
        </SliderWrapper>
    );
};
