import React from 'react';
import styled from 'styled-components';
import {Typography, Card, CardProps} from '@material-ui/core';

function ControlCard({className, children}: CardProps): JSX.Element {
    return (
        <Card
            className={className}
            color={'secondary'}
            variant={'elevation'}
            elevation={4}
        >
            {children}
        </Card>
    );
}

export const PanelItemWrapper = styled(ControlCard)`
    margin: 1ex;
    padding: 1ex 1rem 1ex 1rem;
    min-width: 8rem;
    max-width: 40rem;
    
    font-size: 1ex;
    color: #888f;
`;

type ControlLabelProps = {
    caption: string;
    labelId: string;
}

export const ControlLabel = ({caption, labelId}: ControlLabelProps) => (
    <Typography
        id={labelId}
        variant={'caption'}
        color={'primary'}
        gutterBottom={true}
    >
        {caption}
    </Typography>
);
