import dayjs from 'dayjs';

import { modulo } from '../std/Integer';
import { LocaleLabel } from '../std/locale';

import {DayOfWeekIndex, DayOfWeekOrdinal, FirstDayOfWeekOrdinal} from './WeekModel';

export const REF_SUNDAY = new Date(2020, 0, 5);
export const REF_MONDAY = dayjs(REF_SUNDAY).add(1, 'day').toDate();

export const localDayName = (date: Date, locale: LocaleLabel) =>
    new Intl.DateTimeFormat(locale ? locale : 'default', {weekday: 'long'}).format(date);

export const localisedSunday = (locale: LocaleLabel) => localDayName(REF_SUNDAY, locale);
export const localisedMonday = (locale: LocaleLabel) => localDayName(REF_MONDAY, locale);
export const monthStartIndex: (_m: DayOfWeekOrdinal, _w: FirstDayOfWeekOrdinal) => DayOfWeekIndex  = (
    monthStartsOn: DayOfWeekOrdinal,
    weekStartsOn: FirstDayOfWeekOrdinal
) =>
    modulo(monthStartsOn - weekStartsOn, 7) as DayOfWeekIndex;
