import React, {FC} from 'react';
import styled from 'styled-components';

import {range} from './std/Integer';
import {LocaleLabel} from './std/locale';

import {Year} from './model/YearModel';
import {MonthNumber} from './model/MonthModel';
import {DayOfWeek, WeekDefinition} from './model/WeekModel';
import {newMonthInfo} from './model/month';

import {ImageFrameProps} from './PhotoPane';
import {DayCell} from './DayCell';
import {FontSizeProps} from './FontSizeProps';
import {StyledWrapper, WrapperStyle} from './StyledWrapper';

const GridWrapper = styled.div`
    padding: 1%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr repeat(6, 2fr);
    column-gap: 1%;
    row-gap: 1%;
`;

const DayName = styled.div(({fontSizePrint, fontSizeScreen}: FontSizeProps) => `
    display: grid;
    justify-content: center;
    align-content: space-around;
    
    font-size: small;

    @media print {
        font-size: ${fontSizePrint}vh;
    }

    @media screen {
        background-color: #fdfdfd;
        font-size: ${fontSizeScreen}vh;
    }
`);

const NameComponent = styled.div(({fontSizePrint, fontSizeScreen}: FontSizeProps) => `
    display: flex;
    justify-content: center;
    align-items: center;
    // font-family: 'Kurale', serif;
    font-family: 'Cinzel', serif;
    
    @media print {
        font-size: ${fontSizePrint}vh;
    }

    @media screen {
        font-size: ${fontSizeScreen}vh;
    }
`);

type MonthProps = {
    year: Year;
    monthNumber: MonthNumber;
    weekDefinition: WeekDefinition;
    locale: LocaleLabel;
    PhotoPane: React.FunctionComponent<ImageFrameProps>;
    nameComponentProps: FontSizeProps;
    datePresentationProps: FontSizeProps;
    dayNameProps: FontSizeProps;
    wStyle: WrapperStyle;
};

export const Month: FC<MonthProps> = (
    {
        year,
        monthNumber,
        weekDefinition,
        locale,
        wStyle,
        PhotoPane,
        nameComponentProps,
        datePresentationProps,
        dayNameProps
    }
) => {
    const monthInfo = newMonthInfo(year, monthNumber);
    const gridInfo = monthInfo.gridInfo(weekDefinition);
    const gridLength = gridInfo.size;
    const name = monthInfo.name(locale);

    return (
        <StyledWrapper wStyle={wStyle}>
            <PhotoPane label={`image-for-${monthNumber.toString()}`}/>
            <NameComponent {...nameComponentProps}>{name}</NameComponent>
            <GridWrapper>
                {
                    weekDefinition.days
                        .sort((d1, d2) => d1.index - d2.index)
                        .map((day: DayOfWeek) =>
                            <DayName
                                key={day.index}
                                {...dayNameProps}
                            >
                                {day.name}
                            </DayName>
                        )
                }
                {
                    range(gridLength, 1).map(gridIndex =>
                        <DayCell
                            key={gridIndex}
                            dayOfMonth={gridInfo.dayOfMonth(gridIndex)}
                            inCurrentMonth={gridInfo.isIndexInMonth(gridIndex)}
                            {...datePresentationProps}
                        />
                    )
                }
            </GridWrapper>
        </StyledWrapper>
    );
};
