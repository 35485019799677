import React, {FC} from 'react';
import styled from 'styled-components';

import {LocaleLabel} from './std/locale';

import {Year} from './model/YearModel';
import {FirstDayOfWeek, WeekDefinition} from './model/WeekModel';
import {listMonthNumbers} from './model/MonthFunctions';
import {defineWeekByFirstDay} from './model/week';
import {FontSizeProps} from './FontSizeProps';

import {CalendarMode} from './model/CalendarMode';
import {Month} from './Month';
import {ImageFrameProps, PhotoPane} from './PhotoPane';
import {StyledWrapper, WrapperStyle} from './StyledWrapper';

const EmptyPane = styled.div((_: ImageFrameProps) => `
    visibility: hidden;
`);

const imagePane = (hasImagePane: boolean) => hasImagePane
    ? PhotoPane
    : EmptyPane;

type HeightProps = {
    print: number;
    screen?: number;
}

type ModeDependentProps = {
    hasImagePane: boolean;
    calendarWrapperStyle: WrapperStyle;
    monthWrapperStyle: WrapperStyle;
    height?: HeightProps;
    nameComponentProps: FontSizeProps;
    datePresentationProps: FontSizeProps;
    dayNameProps: FontSizeProps;
}

type ModeProps = (mode: CalendarMode) => ModeDependentProps;

const modeProps: ModeProps = (mode: CalendarMode) => {
    const pageHeight = 96;
    const monthCount = 12;
    let monthsPerRow: number = 1;
    let rowsPerPage: number = 1;
    let rowsPerPagePrint: number = 1;
    let rowsPerPageScreen: number = 1;
    const rowGap = 2;
    const calendarBaseStyle: string = `
        display: grid;
        justify-content: center;
        align-content: space-evenly;
        row-gap: ${rowGap}vh;
        column-gap: 2vw;
    `;
    const monthBaseStyle: string = `
        display: grid;
        background: #fff;
        grid-template-columns: 1fr;
    `;
    let monthNameBoxHeightPct: number = 10;
    let imagePaneHeightPct: number = 0;

    switch (mode) {
        case "full-page-portrait-with-image":
            imagePaneHeightPct = 50;
            monthNameBoxHeightPct = 6;

            return {
                hasImagePane: imagePaneHeightPct > 0,
                calendarWrapperStyle: `
                    ${calendarBaseStyle}
                    height: ${100 * monthCount / monthsPerRow / rowsPerPage}vh;
                    grid-template-columns: calc(min(${pageHeight - rowGap}vh / 1.414, 100vw));
                    grid-template-rows: repeat(12, ${pageHeight}vh);
                `,
                monthWrapperStyle: `
                    ${monthBaseStyle}
                    @media screen { box-shadow: 0 0 10px 2px #1111 };
                    grid-template-rows: ${imagePaneHeightPct}% ${monthNameBoxHeightPct}% ${100 - imagePaneHeightPct - monthNameBoxHeightPct}%;
                `,
                nameComponentProps: {
                    fontSizePrint: 4,
                    fontSizeScreen: 4
                },
                datePresentationProps: {
                    fontSizePrint: 1.5,
                    fontSizeScreen: 1.5
                },
                dayNameProps: {
                    fontSizePrint: 1.2,
                    fontSizeScreen: 1.2
                }
            };
        case "compact-landscape-3x2x2":
            monthsPerRow = 3;
            rowsPerPage = 2;

            return {
                hasImagePane: imagePaneHeightPct > 0,
                calendarWrapperStyle: `
                    ${calendarBaseStyle}
                    grid-template-columns: repeat(${monthsPerRow}, 1fr);

                    @media print {
                        height: ${100 * monthCount / monthsPerRow / rowsPerPage}vh;
                        grid-template-rows: repeat(${monthCount / monthsPerRow}, ${pageHeight / rowsPerPage - rowGap}vh);
                    }
                    
                    @media screen {
                        height: ${100 * monthCount / monthsPerRow / rowsPerPage}vh;
                        grid-template-rows: repeat(${monthCount / monthsPerRow}, ${pageHeight / rowsPerPage - rowGap}vh);
                    }
                `,
                monthWrapperStyle: `
                    ${monthBaseStyle}
                    grid-template-rows: ${imagePaneHeightPct}% ${monthNameBoxHeightPct}% ${100 - imagePaneHeightPct - monthNameBoxHeightPct}%;
                    box-shadow: 0 0 10px 2px #1111;
                `,
                height: {
                    print: 200
                },
                nameComponentProps: {
                    fontSizePrint: 3,
                    fontSizeScreen: 2.5
                },
                datePresentationProps: {
                    fontSizePrint: 1,
                    fontSizeScreen: 1
                },
                dayNameProps: {
                    fontSizePrint: 0.8,
                    fontSizeScreen: 0.7
                }
            };
        case "compact-portrait-3x4":
            monthsPerRow = 3;
            rowsPerPagePrint = 4;
            rowsPerPageScreen = 2;

            return {
                hasImagePane: imagePaneHeightPct > 0,
                calendarWrapperStyle: `
                    ${calendarBaseStyle}
                    grid-template-columns: repeat(${monthsPerRow}, 1fr);

                    @media print {
                        height: ${100 * monthCount / rowsPerPagePrint / monthsPerRow}vh;
                        grid-template-rows: repeat(${rowsPerPagePrint}, ${pageHeight / rowsPerPagePrint - rowGap}vh);
                    }
                    
                    @media screen {
                        height: ${100 * monthCount / rowsPerPageScreen / monthsPerRow}vh;
                        grid-template-rows: repeat(${monthCount / monthsPerRow}, ${pageHeight / rowsPerPageScreen - rowGap}vh);
                    }
                `,
                monthWrapperStyle: `
                    ${monthBaseStyle}
                    grid-template-rows: ${imagePaneHeightPct}% ${monthNameBoxHeightPct}% ${100 - imagePaneHeightPct - monthNameBoxHeightPct}%;
                    box-shadow: 0 0 10px 2px #1111;
                `,
                height: {
                    print: 100,
                    screen: 200
                },
                nameComponentProps: {
                    fontSizePrint: 2,
                    fontSizeScreen: 2.5
                },
                datePresentationProps: {
                    fontSizePrint: 1,
                    fontSizeScreen: 1
                },
                dayNameProps: {
                    fontSizePrint: 0.6,
                    fontSizeScreen: 1.1
                }
            };
        case "split-a4-landscape-with-image":
            imagePaneHeightPct = 50;
            monthNameBoxHeightPct = 6;
            const monthNameBoxHeightVh = 8;
            rowsPerPagePrint = 0.5;

            return {
                hasImagePane: true,
                calendarWrapperStyle: `
                    ${calendarBaseStyle}
                    @media print {
                        height: ${100 * monthCount / monthsPerRow / rowsPerPagePrint}vh;
                        grid-template-columns: calc(min(${pageHeight - rowGap}vh * 1.414, 100vw));
                        grid-template-rows: repeat(12, auto);
                        row-gap: ${rowGap * 2}vh;
                    }

                    @media screen {
                        height: ${100 * monthCount / monthsPerRow / rowsPerPageScreen}vh;
                        grid-template-columns: calc(min(${pageHeight - rowGap}vh / 1.414, 100vw));
                        grid-template-rows: repeat(12, ${pageHeight - rowGap}vh);
                    }
                `,
                monthWrapperStyle: `
                    ${monthBaseStyle}
                    @media print {
                        grid-template-rows: ${pageHeight - rowGap}vh ${monthNameBoxHeightVh}vh ${pageHeight - rowGap - monthNameBoxHeightVh}vh;
                        align-content: space-between;
                        row-gap: ${rowGap}vh;
                    }

                    @media screen {
                        box-shadow: 0 0 10px 2px #1111;
                        grid-template-rows: ${imagePaneHeightPct}% ${monthNameBoxHeightPct}% ${100 - imagePaneHeightPct - monthNameBoxHeightPct}%;
                    };
                `,
                nameComponentProps: {
                    fontSizePrint: 6,
                    fontSizeScreen: 4
                },
                datePresentationProps: {
                    fontSizePrint: 1.5,
                    fontSizeScreen: 1.5
                },
                dayNameProps: {
                    fontSizePrint: 1.2,
                    fontSizeScreen: 1.2
                }
            };
    }
};

type SpikeCalendarProps = {
    year: Year;
    weekStartsOn: FirstDayOfWeek;
    mode: CalendarMode;
    locale: LocaleLabel;
}

export const Calendar: FC<SpikeCalendarProps> = (
    {year, weekStartsOn, mode, locale}
) => {
    const mProps = modeProps(mode);
    const weekDef: WeekDefinition = defineWeekByFirstDay(weekStartsOn, locale);

    return (
        <StyledWrapper wStyle={mProps.calendarWrapperStyle}>
            {
                listMonthNumbers.map(monthNumber => (
                    <Month
                        key={monthNumber}
                        year={year}
                        monthNumber={monthNumber}
                        weekDefinition={weekDef}
                        locale={locale}
                        wStyle={mProps.monthWrapperStyle}
                        PhotoPane={imagePane(mProps.hasImagePane)}
                        nameComponentProps={mProps.nameComponentProps}
                        datePresentationProps={mProps.datePresentationProps}
                        dayNameProps={mProps.dayNameProps}
                    />
                ))
            }
        </StyledWrapper>
    )
};
