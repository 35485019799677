import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import {range} from '../std/Integer';
import { LocaleLabel } from '../std/locale';

import {
    DayOfWeek,
    DayOfWeekIndex,
    DayOfWeekOrdinal,
    FirstDayOfWeek,
    FirstDayOfWeekOrdinal,
    WeekDefinition
} from './WeekModel';

dayjs.extend(weekday);

class Week {
    _days: DayOfWeek[];
    _firstDayOrdinal: FirstDayOfWeekOrdinal;

    constructor(firstDay: FirstDayOfWeek, localeName: LocaleLabel) {
        let locale = localeName || 'default';
        let day0: FirstDayOfWeekOrdinal = firstDay === "sunday" ? 7 : 1;
        let narrowNameOptions = {weekday: 'narrow'};
        let fullNameOptions = {weekday: 'long'};

        this._days = range(7, 1).map(dayOrdinal => {
            let dayJsDayOfWeek = dayOrdinal % 7;
            return {
                name: new Intl.DateTimeFormat(locale, narrowNameOptions).format(
                    dayjs().weekday(dayJsDayOfWeek).toDate()
                ),
                fullName: new Intl.DateTimeFormat(locale, fullNameOptions).format(
                    dayjs().weekday(dayJsDayOfWeek).toDate()
                ),
                ordinal: dayOrdinal as DayOfWeekOrdinal,
                index: (firstDay === "monday"
                    ? dayOrdinal - 1
                    : dayOrdinal % 7) as DayOfWeekIndex
            };
        });
        this._firstDayOrdinal = day0 as FirstDayOfWeekOrdinal;
    }

    getDays(): DayOfWeek[] {
        return this._days;
    }

    getFirstDayIndex(): FirstDayOfWeekOrdinal {
        return this._firstDayOrdinal;
    }

    isFirstDayOfWeek: (_: DayOfWeekOrdinal) => boolean = (dayOrdinal: DayOfWeekOrdinal) =>
        dayOrdinal === this._firstDayOrdinal;
}

export const defineWeekByFirstDay: (_f: FirstDayOfWeek, _l: LocaleLabel) => WeekDefinition = (
    firstDay: FirstDayOfWeek,
    locale: LocaleLabel
) => {
    const week = new Week(firstDay, locale);

    return {
        days: week.getDays(),
        firstDayOrdinal: week.getFirstDayIndex(),
        isFirstDayOfWeek: week.isFirstDayOfWeek
    }
}
