import dayjs, {Dayjs} from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import {modulo} from '../std/Integer';
import {LocaleLabel} from '../std/locale';

import {Year} from "./YearModel";
import {
    DayOfMonth, DaysInMonth, GridSize, MonthInfo, MonthNumber
} from './MonthModel';
import {
    DayOfWeekIndex, DayOfWeekOrdinal, FirstDayOfWeekOrdinal, WeekDefinition
} from './WeekModel';

dayjs.extend(weekday);

const daysInMonth: (monthStartDate: Dayjs) => DaysInMonth = (startDate: Dayjs) => {
    let daysInMonth = startDate.daysInMonth();
    return (daysInMonth < 28 ? 28 : daysInMonth > 31 ? 31 : daysInMonth) as DaysInMonth;
};

class Grid {
    private readonly _weekStartsOn: FirstDayOfWeekOrdinal;
    private readonly _monthStartsOn: DayOfWeekOrdinal;
    private readonly _monthStartIndex: number;
    private readonly _monthLength: DaysInMonth;
    private readonly _previousMonthLength: DaysInMonth;
    public readonly size: GridSize;

    constructor(
        weekDefinition: WeekDefinition,
        monthStartsOn: DayOfWeekOrdinal,
        monthLength: DaysInMonth,
        previousMonthLength: DaysInMonth
    ) {
        this._weekStartsOn = weekDefinition.firstDayOrdinal;
        this._monthStartsOn = monthStartsOn;
        this._monthStartIndex = modulo(this._monthStartsOn - this._weekStartsOn, 7) as DayOfWeekIndex;
        this._monthLength = monthLength;
        this._previousMonthLength = previousMonthLength;
        this.size = (this._monthLength === 28 && weekDefinition.isFirstDayOfWeek(monthStartsOn)
            ? 28
            : this._monthStartIndex + monthLength > 35
                ? 42
                : 35) as GridSize;
    }

    public isIndexInMonth = (index: number) => {
        const startDayIndex = this._monthStartIndex;

        return index > startDayIndex && index <= this._monthLength + startDayIndex;
    };

    public dayOfMonth = (index: number) => {
        const startDayIndex = this._monthStartIndex;

        return (this.isIndexInMonth(index)
            ? index - startDayIndex
            : index <= startDayIndex
                ? this._previousMonthLength + index - startDayIndex
                : index - this._monthLength - startDayIndex) as DayOfMonth;
    };
}

class Month {
    private readonly startDate: Date;
    public readonly startDay: DayOfWeekOrdinal;
    public readonly daysInMonth: DaysInMonth;
    public readonly daysInPreviousMonth: DaysInMonth;

    constructor(year: Year, monthNumber: MonthNumber) {
        this.startDate = new Date(Date.UTC(year, monthNumber - 1, 1));
        let dayjsStartDate = dayjs(this.startDate);

        let startDay: number = dayjsStartDate.weekday(); // 0 = Sunday, 1 = Monday, etc.
        this.startDay = (startDay === 0 ? 7 : startDay) as DayOfWeekOrdinal;

        this.daysInMonth = daysInMonth(dayjsStartDate);
        this.daysInPreviousMonth = daysInMonth(dayjsStartDate.subtract(1, 'month'));
    }

    public getName = (locale: LocaleLabel) => new Intl.DateTimeFormat(
        locale,
        {month: 'long'}
    ).format(this.startDate);

    public grid = (weekDefinition: WeekDefinition) => new Grid(
        weekDefinition,
        this.startDay,
        this.daysInMonth,
        this.daysInPreviousMonth
    );
}

type MonthFactory = (
    year: Year,
    month: MonthNumber
) => MonthInfo;

export const newMonthInfo: MonthFactory = (year: Year, monthNumber: MonthNumber) => {
    const monthInfo = new Month(year, monthNumber);

    return {
        name: monthInfo.getName,
        startDay: monthInfo.startDay,
        daysInMonth: monthInfo.daysInMonth,
        daysInPreviousMonth: monthInfo.daysInPreviousMonth,
        gridInfo: monthInfo.grid
    };
}
