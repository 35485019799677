import React, {FC, useState} from 'react';
import styled from 'styled-components';
import {DropTargetMonitor, useDrop} from 'react-dnd';
import {NativeTypes} from 'react-dnd-html5-backend';
import {
    ImageOutlined as AttachImageIcon,
    CancelOutlined as RemoveImageIcon
} from '@material-ui/icons';
import {createStyles, IconButton, InputLabel, Theme, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const Site = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Frame = styled.div`
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ImageRemoverBox = styled.div`
    @media print {
        display: none;
    }

    @media screen {
        position: absolute;
        top: 0;
        right: 0;
        background: radial-gradient(circle, #fff, #fff1, #fff0);
        border-radius: 50%;
    }
`;

const FramedImage = styled.img`
    display: block;
    margin-top: 1%;
    margin-bottom: 1%;
    max-width: 95%;
    max-height: 95%;
    object-fit: scale-down;
`;

type RemoverProps = {
    onRemove: () => void;
}

const Remover: FC<RemoverProps> = ({onRemove}) => {
    const onClick = (_event: any) => {
        onRemove();
    };

    return (
        <ImageRemoverBox>
            <IconButton aria-label='remove picture' component='span' onClick={onClick}>
                <RemoveImageIcon fontSize={"default"}/>
            </IconButton>
        </ImageRemoverBox>
    );
};

type EncodedImage = {
    base64Encoding: string;
    mimeType: string;
}

type ImageProps = {
    image: EncodedImage;
    label: string;
    onRemove: () => void;
}

const Image: FC<ImageProps> = ({image, label, onRemove}) => {
    const {mimeType, base64Encoding} = image;

    return (
        <Site>
            <Frame>
                <FramedImage
                    src={`data: ${mimeType};base64, ${base64Encoding}`}
                    alt={label}
                />
            </Frame>
            <Remover onRemove={onRemove} />
        </Site>
    );
};

type ImagePlaceholderProps = {
    imageKey: string;
    isOver: boolean;
    canDrop: boolean;
    onImageSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImagePlaceholder: FC<ImagePlaceholderProps> = (
    {isOver, canDrop, onImageSelect, imageKey}
) => {
    return (
        <ImagePlaceholderWrapper>
            <ScreenOnly>
                <Typography>
                    {isOver ? 'Apply' : canDrop ? 'Choose monthNumber' : 'Attach image'}
                </Typography>
                <AttachImage imageKey={imageKey} onFileSelect={onImageSelect} />
            </ScreenOnly>
        </ImagePlaceholderWrapper>
    );
};

const ScreenOnly = styled.div`
    @media print {
        display: none;
    }
    
    @media screen {
        display: flex;
        flex-direction: column;
        
        justify-content: center;
        align-items: center;
    }
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            display: 'none'
        }
    })
);

type AttachImageProps = {
    imageKey: string;
    onFileSelect: React.ChangeEventHandler<HTMLInputElement>;
}

const AttachImage: FC<AttachImageProps> = ({imageKey, onFileSelect}) => {
    const classes = useStyles();

    return (
        <>
            <InputLabel htmlFor={imageKey}>
                <IconButton aria-label='upload picture' component='span'>
                    <AttachImageIcon fontSize={"large"} />
                </IconButton>
            </InputLabel>
            <input accept='image/*' type='file' className={classes.input} id={imageKey} onChange={onFileSelect} />
        </>
    );
};

const ImagePlaceholderWrapper = styled.div`
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 0;
    width: 90%;

    @media screen {
        display: flex;
        flex-direction: column;
        justify-items: center;
        justify-content: center;
        align-items: center;
        
        border-radius: 0.1vw;
        
        background: radial-gradient(30vw circle, #888a, #444a);
    }
`;

const DropZoneWrapper = styled.div`
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
`;

export type ImageFrameProps = {
    label?: string;
}

export const PhotoPane: FC<ImageFrameProps> = ({label}) => {
    const [image, setImage] = useState<EncodedImage | undefined>();

    const [{canDrop, isOver}, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop(_item, monitor) {
            if (handleFileDrop) {
                handleFileDrop(monitor);
            }
        },
        collect: (monitor: DropTargetMonitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const maybeSetImage = (files: FileList | null) => {
        if (files && files.length > 0) {
            let file = files[0];
            let reader = new FileReader();
            reader.onload = onPhotoLoadSuccess(file.type);
            reader.readAsBinaryString(file);
        }
    };

    const removeImage = () => {
        setImage(undefined);
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.target?.files;
        maybeSetImage(files);
    };

    const handleFileDrop = (monitor: DropTargetMonitor) => {
        if (monitor) {
            const files = monitor.getItem().files
            maybeSetImage(files);
        }
    };

    const onPhotoLoadSuccess = (imageType: string) => (pe: ProgressEvent) => {
        let reader = pe.target as FileReader;
        let content = reader.result as string;
        let base64encoding = btoa(content);
        setImage({base64Encoding: base64encoding, mimeType: imageType});
    };

    return (
        <DropZoneWrapper ref={drop}>
            {image ?
                (
                    <Image image={image} label={label!} onRemove={removeImage} />
                ) :
                (
                    <ImagePlaceholder
                        imageKey={label!}
                        isOver={isOver}
                        canDrop={canDrop}
                        onImageSelect={handleImageUpload}
                    />
                )}
        </DropZoneWrapper>
    );
};
