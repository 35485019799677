import React from 'react';
import styled from 'styled-components';
import {MenuItem, Select} from '@material-ui/core';

import {CalendarMode} from './model/CalendarMode';
import {listModes} from './model/CalendarFunctions';

import {ControlLabel, PanelItemWrapper} from './PanelItemWrapper';

const SelectWrapper = styled(PanelItemWrapper)`
`;

type CalendarModeChooserProps = {
    value: CalendarMode;
    onChange(newValue: CalendarMode): void;
}

export const CalendarModeChooser = (
    {value, onChange}: CalendarModeChooserProps
) => {
    const handleProfileChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as CalendarMode);
    }

    return (
        <SelectWrapper>
            <ControlLabel caption={'Layout Profile'} labelId={'mode-profile'}/>
            <Select
                labelId={'mode-profile'}
                value={value}
                fullWidth={true}
                autoWidth={false}
                onChange={handleProfileChange}
            >
                {
                    listModes.map(modeName => (
                        <MenuItem key={modeName} value={modeName}>{modeName}</MenuItem>
                    ))
                }
            </Select>
        </SelectWrapper>
    );
};
